export const socialMediaAccounts = {
  linkedin: {
    icon: "fab fa-linkedin",
    link: "https://www.linkedin.com/in/mahmut-kaya-b9832614a/",
  },
  github: { icon: "fab fa-github", link: "https://github.com/mahmutkaya" },
  twitter: {
    icon: "fa-brands fa-square-x-twitter",
    link: "https://x.com/mahmutkaya___",
  },
};
